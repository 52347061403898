<template>
  <el-dialog 
    class="my-dialog"
    :width="width"
    :title="title"
    :close-on-click-modal="false"
    :visible.sync="closeFlag"
    :before-close="closeModel"
    append-to-body
    destroy-on-close
    center
  >
    <div class="show-list" v-if="finalPics && finalPics.length">
        <div class="pics-list"  v-for="(item, index) in finalPics" :key="index">
          <el-image 
            :key="index"
            style="width: 100px; height: 110px"
            :src="item.url" 
            :preview-src-list="[item.url]">
          </el-image>
          <el-checkbox v-model="item.checked"></el-checkbox>
        </div>
      <!-- </el-checkbox-group> -->
    </div>
    <div v-else class="no-data">暂无数据！</div>
    <div class="forms-btns" slot="footer">
      <el-button type="primary" size="small" @click="sure" v-if="finalPics && finalPics.length">确定</el-button>
      <el-button @click="closeModel" size="small"> 取消 </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'showPics',
  props: {
    width: {
      type: String,
      default: '900px'
    },
    propName: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    allpics: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      closeFlag: false,
      finalPics: []
    }
  },
  mounted() {
    
  },
  methods: {
    showModel(){
      this.closeFlag = true
    },
    sure(){
      let allpics = this.finalPics || [],
        pics = []
      allpics.forEach(cur => {
        if(cur.checked){
          pics.push(cur.url)
        }
      })
      if(pics.length){
        this.$emit("getshowpics", {
          propName: this.propName, 
          pics
        });
        this.closeModel()
      }else{
        this.closeModel()
        // this.message('请选择', "warning")
      }
    },
    closeModel() {
      this.closeFlag = false
    },
  },
  watch: {
    'allpics': {
      handler(val){
        // finalPics
        if(val){
          let pic = val || [],
            allPics = []
          pic.forEach(cur => {
            allPics.push({
              url: cur,
              checked: false
            })
          })
          this.finalPics = allPics
        }
      },
      deep: true
    }
  }
};
</script>
<style lang="less" scoped>
  .show-list{
    display: flex;
    flex-wrap: wrap;
  }
  .my-free-img{
    width: 100px;
    height: 110px;
  }
  .pics-list{
    width: 100px;
    padding: 10px;
  }
  ::v-deep .el-checkbox{
    display: block;
    text-align: center;
    margin: 10px;
  }
  .no-data{
    font-size: 16px;
    text-align: center;
    padding-top: 30px;
  }
</style>

