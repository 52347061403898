<!-- 主体收购来源选择窗 -->
<template>
  <el-dialog
    class="my-dialog"
    :title="title"
    width="1000px"
    :close-on-click-modal="false"
    :visible.sync="closeFlag"
    :before-close="closeModel"
    append-to-body
    destroy-on-close
    center
  >
    <div>
      <div class="title-search my-table-list">
        <el-form :inline="true" :model="searchForm" class="demo-form-inline">
          <div class="title-search-line">
            <el-form-item label="所属区域:">
              <el-input size="small" class="my-input-btn my-input-btn1" :disabled="true" placeholder="请选择" suffix-icon="el-icon-arrow-down" @click.native="chooseType('getpickarea')" v-model="searchForm.address"></el-input>
            </el-form-item>
            <el-form-item label="产品名称:">
              <el-input size="small" v-model="searchForm.productName" placeholder="请输入产品名称" clearable></el-input>
            </el-form-item>
            <el-form-item label="供应商:">
              <el-input size="small" v-model="searchForm.supplierName" placeholder="请输入供应商" clearable></el-input>
            </el-form-item>
            <el-form-item label="收购时间:">
              <el-date-picker
                class="my-date-range"
                v-model="timeRange"
                @change="timeChange"
                type="daterange"
                align="right"
                unlink-panels
                size="small"
                range-separator="到"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>
            <el-button type="primary" size="small" @click="getData(true)">查询</el-button>
          </div>
        </el-form>
      </div>
      <el-table 
        ref="table" 
        class="my-table min-dialog-height"
        size="small" 
        :data="listData" 
        highlight-current-row 
        border
        v-loading="loading"  
        :element-loading-text="loadtext" 
        height="calc(100vh - 450px)"
        v-bind="$attrs"
        v-on="$listeners"
      >
        <el-table-column align="center" type="index" width="70" label="序号">
          <template slot-scope="scope">
            <span>{{
              pageparm
                ? (pageparm.page - 1) * pageparm.limit +
                  scope.$index +
                  1
                : scope.$index + 1
            }}</span>
          </template>
        </el-table-column>
        
        <el-table-column
          v-for="(headData, index) in tableHead"
          :key="index"
          :prop="headData.prop"
          :label="headData.label"
          :width="headData.width"
          :align="headData.align || 'center'"
          :header-align="headData.headerAlign || 'center'"
          :min-width="headData.minWidth"
          :show-overflow-tooltip="false"
        >
          <template slot-scope="scope">
            {{ scope.row[headData.prop] || '' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" fixed="right" width="150">
          <template slot-scope="scope">
            <el-button size="small" type="text" @click="choose(scope.row, scope.$index)">选择</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination v-bind:child-msg="pageparm" @refreshTable="refreshTable"></pagination>
      <pick-area ref="getpickarea" @getpickarea="getpickarea" sureBtnAlltime></pick-area>
    </div>
  </el-dialog>
</template>
<script>
  import pagination from '@/components/pagination.vue'
  import pickArea from '@/views/common/pickArea.vue'
  import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
  export default {
    name: 'acquisition',
    components: {
      pagination,
      pickArea
    },
    props:{
    },
    data() {
      let title = '收购来源'
      return {
        timeRange: '',
        pickerOptions: {
          shortcuts: [
          {
            text: '最近一月',
            onClick(picker) {
              let obj = utils.getDays('1')
              picker.$emit('pick', [obj.start, obj.end])
            }
          }, {
            text: '最近一季',
            onClick(picker) {
              let obj = utils.getDays('3')
              picker.$emit('pick', [obj.start, obj.end])
            }
          }, {
            text: '最近一年',
            onClick(picker) {
              let obj = utils.getDays('12')
              picker.$emit('pick', [obj.start, obj.end])
            }
          }]
        },
        closeFlag: false,
        loading: false,
        title: title,
        searchForm: {
          page: CONSTPARAMS.page,
          limit: CONSTPARAMS.limit,
          consigneeId: utils.getBodyId(),
          address: '',
          areaCode: '',
          areaLevel: '',
          supplierName: '',
          productName: '',
          startTime: '',
          endTime: ''
        },
        urlName: 'commontransactionrecordpage',
        tableHead: [{
          prop: 'supplierName',
          label: '供应商名称'
        },{
          prop: 'areaName',
          label: '所属区域'
        },{
          prop: 'productName',
          label: '产品名称'
        },{
          prop: 'createTime',
          label: '收购时间',
          width: 180
        },{
          prop: 'practicaWeight',
          label: '总收购重量',
          width: 180
        }],
        initForm: {
        },
        loadtext: '加载中',
        pageName: title,
        tableName: `${title}列表`,
        loading: false,
        tableHeight: null,
        listData: [],
        pageparm: {
          page: CONSTPARAMS.page,
          limit: CONSTPARAMS.limit,
          total: 0
        }
      }
    },
    created() {
      this.initTable()
      let obj = utils.getDays('1')
      this.timeRange = [obj.start, obj.end]
      this.searchForm.startTime = obj.start
      this.searchForm.endTime = obj.end
    },
    mounted(){
      this.getTopHeight()
    },
    methods: {
      chooseType(name){
        this.$refs[name].initData()
      },
      getpickarea(data){
        let address = ''
        data.forEach(cur => {
          if(cur.areaCode){
            address += cur.areaName
          }
        })
        let len = data && data.length || 0

        this.searchForm.address = address || ''
        this.searchForm.areaCode = len ? data[len - 1].areaCode : ''
        this.searchForm.areaLevel = len ? data[len - 1].level : ''
      },
      timeChange(val){
        let startTime = '',
          endTime = ''
        if(val){
          startTime = utils.dateFormat(val[0], 'yyyy-MM-dd')
          endTime = utils.dateFormat(val[1], 'yyyy-MM-dd')
        }
        this.searchForm.startTime = startTime
        this.searchForm.endTime = endTime
      },
      async initTable(){
        this.getData()
      },
      initData(){
        this.closeFlag = true
        this.$nextTick(() => {
          this.$refs.table &&  this.$refs.table.doLayout()
        })
      },
      choose(data, index){
        this.$emit('getacquisition', data)
        this.closeModel()
      },
      async add(){
        // let id = await utils.getNewId(),
        let obj = {
          ...this.initForm
        }
        this.$refs.editForm.initData(`新增${this.pageName}`, 'add', obj)
      },
      view(data, index){
        this.getInfo('look', data, index)
      },
      edit(data, index){
        this.getInfo('edit', data, index)
      },
      getInfo(type, data, index){
        let _this = this
        request.commonsupplierDetail(data.supplierId).then(res => {
          if(res.code == 200){
            let result = res.data
            if(!result){
              _this.message('此条数据信息获取为空！', "error")
              return
            }
            result = {
              ...result
            }
            delete result.createTime
            this.$refs.editForm.initData(`${type == 'edit' ? '修改' : '查看'}${this.pageName}`, type, result)
          } else {
            _this.message(res.msg || '', "error")
          }
        }).catch(e => {
          console.log(e)
        })
      },
      del(data, index){
        let _this = this
        _this.$confirm(`确认要删除${data.bodyName || ''}这条记录吗？`, "提示", {
          cancelButtonText: "取消",
          confirmButtonText: "确定",
          type: "warning",
        })
        .then(() => {
          request.commondeletesupplier([data.supplierId]).then(res => {
            if(res.code == 200){
              _this.getData()
              _this.message('删除成功！', "success");
            }else{
              _this.message(res.msg || '', "error");
            }
          }).catch(e => {
            console.log(e)
          })
        }).catch(() => {})
      },
      getTopHeight(){
        let getTopHeight = utils.getTopHeight()
        this.tableHeight = `calc(100% - ${getTopHeight}px)`
      },
      refreshTable(parm) {
        this.searchForm.page = parm.page
        this.searchForm.limit = parm.limit
        this.getData()
      },
      getData(flag = false){
        let _this = this
        if(flag){
          this.searchForm.page = CONSTPARAMS.page
          this.searchForm.limit = CONSTPARAMS.limit
        }
        _this.loading = true
        request[_this.urlName](_this.searchForm).then(res => {
          _this.loading = false
          if(res.code == 200){
            let lists = res.data.list || []
            lists = lists.map(item => {
              return {
                ...item,
                areaName: (item.province || '') + (item.city || '') + (item.county || '') + (item.town || '') + (item.village || ''),
                practicaWeight: (item.practicaWeight || '') + (item.weightUnit || '')
              }
            })
            _this.listData = lists
            _this.pageparm.page = _this.searchForm.page
            _this.pageparm.total = res.data.total || 0
            _this.$nextTick(() => {
              _this.$refs.table && _this.$refs.table.doLayout()
            })
          }else{
            _this.message(res.msg || '', "error")
          }
        }).catch(e => {
          console.log(e)
          _this.loading = false
        })
      },
      closeModel() {
        this.closeFlag = false
      },
    },
  }
</script>
<style lang="scss" scoped>
</style>