<template>
  <el-dialog
    class="my-dialog1"
    title="扫码预览效果"
    width="420px"
    :close-on-click-modal="false"
    :visible.sync="closeFlag"
    :before-close="closeModel"
    append-to-body
    destroy-on-close
    center
  > 
    <source-cont v-if="code" :code="code"></source-cont>
    <div class="forms-btns" slot="footer">
      <el-button @click="closeModel" size="small"> 关闭</el-button>
    </div>
  </el-dialog>
</template>
<script>
import sourceCont from './sourceCont.vue'
export default {
  name: "sourcePage",
  components: {
    sourceCont
  },
  props:{
  },
  data() {
    return {   
      closeFlag: false,
      code: ''
    };
  },
  created() {
	},
  mounted(){
  },
  methods: {
    initData(code){
      this.code = code
      this.closeFlag = true
    },
    closeModel() {
      this.closeFlag = false
    },
  }
};
</script>

<style lang="scss" scoped>
.my-dialog1 ::v-deep .el-dialog--center .el-dialog__body{
  padding: 0;
}
.my-dialog1 ::v-deep .el-dialog--center .el-dialog__body{
  height: 683px;
  min-height: 683px;
  max-height: 683px;
}
.my-dialog1 ::v-deep .el-dialog {
  margin-top: 1vh !important;
}
.source-title{
  background-color: #fff;
  height: 40px;
}
.line10{
  padding-top: 10px;
  background-color: #f0f3f6;
}
.source-page-title ::v-deep .el-tabs__header{
  margin: 0;
}
.source-content{
  overflow-y: auto;
  background-color: #f0f3f6;
  padding: 10px;
}
.nor-source-content{
  max-height: calc(100% - 20px);
}
.menu-source-content{
  max-height: calc(100% - 65px);
}
.img100{
  width: 100%;
  height: 120px;
  margin-bottom: 6px;
}
.img100-2{
  width: 100%;
}
.mb10{
  margin-bottom: 10px;
}
.mt10{
  margin-top: 10px;
}
.ml5{
  margin-left: 5px;
}
.source-item{
  background-color: #fff;
  margin-bottom: 10px;
  border-radius: 10px;
  padding: 10px 0;
}
.source-item1{
  display: flex;
}
.item1-left{
  // width: 80%;
}
.item1-right{
  flex-grow: 0;
  flex-shrink: 0;
}
.item-content > div{
  padding: 5px 10px;
  word-wrap: break-word;
  word-break: break-all;
  font-size: 14px;
  line-height: 26px;
}
.rich-texts{
  padding: 0 10px;
  padding-bottom: 5px;
}
.font14{
  font-size: 14px;
}
.item1-r-b{
  border-radius: 10px;
  border: 2px solid #f0f3f6;
  width: 110px;
  margin-right: 7px;
  padding: 10px;
  text-align: center;
}
.imgi1{
  width: 110px;
  height: 120px;
}
.imgi4{
  width: 110px;
  height: 30px;
  cursor: pointer;
}
.imgi2{
  width: 110px;
  height: 33px;
  cursor: pointer;
}
.item1-r-t{
  border-radius: 10px;
  border: 2px solid #f0f3f6;
  width: 110px;
  margin-top: 10px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
}
.imgi3{
  width: 60px;
  height: 60px;
}
.item1-red{
  color: red;
  font-weight: bolder;
}
.item1-part{
  display: flex;
  align-items: center;
  font-size: 15px;
  padding: 10px;
}
.item-title{
  width: 90%;
  padding-left: 5%;
}
.item-size1{
  font-size: 14px;
  font-weight: bolder;
}
.item-size2{
  font-size: 13px;
  color: gray;
}
.item-size3{
  font-size: 14px;
  font-weight: bolder;
  text-align: center;
  padding-bottom: 5px;
}
.item-label{
  font-size: 15px;
  padding: 10px;
}
.flex-two-part{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.free-certificate-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  padding: 0 10px;
}
.fc-img{
  width: 100px;
  height: 100px;
}
.fc-item{
  align-self: baseline;
  width: 600px;
  margin-top: 10px;
}
.gray {
  color: rgb(150, 149, 147);
  font-size: 14px;
}
.item-cont{
  padding-bottom: 10px;
}
.fcd-detail-title{
  color: #19191a;
  font-size: 16px;
  padding-left: 12px;
  padding-bottom: 4px;
}
.fcd-detail{
  color: #636466;
  font-size: 14px;
  padding-left: 12px;
  padding-bottom: 12px;
}
.pics-switer{
  padding: 10px;
}
.pics-switer-img{
  border-radius: 10px;
}
.no-data{
  font-size: 14px;
  text-align: left;
  padding: 15px;
}
.no-data2{
  font-size: 14px;
  text-align: center;
  padding: 15px;
}
.swiper-imgs{
  width: 100%;
  height: 100%;
}
.source-video{
  width: 100%;
}
.product4-s {
  height: 250px;
}
.share-btn button{
  border: none;
  background-color: #f0f3f6;
  font-size: 14px;
  padding: 0;
  width: 10px;
  color: #1296db;
}
.all-pics {
  position: relative;
  overflow: hidden;
  background-color: #fff;
  border: 1px solid #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  width: 125px;
  height: 120px;
  margin: 0 8px 8px 0;
  display: inline-block;
}
.avatar {
  width: 100%;
  height: 148px;
  display: block;
}
.item{
  width: 100%;
  height: 180px;
}
.pics-switer ::v-deep .el-carousel__button{
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.source-page{
  height: 100%;
}
.source-page-title ::v-deep .el-tabs__nav-next, .source-page-title ::v-deep .el-tabs__nav-prev{
  display: none;
  // opacity: 0;
}
.source-page-title ::v-deep .el-tabs__nav-scroll{
  overflow: auto;
}
.source-page-title ::v-deep .el-tabs__item.is-active{
  color: #2979f8
}
.source-page-title ::v-deep .el-tabs__active-bar{
  background-color: #0000ff;
}
</style>


