<template>
  <el-dialog 
    class="my-dialog"
    :title="title"
    :width="width"
    :close-on-click-modal="false"
    :visible.sync="closeFlag"
    :before-close="closeModel"
    append-to-body
    destroy-on-close
    center
    :show-close="!loading"
  >
    <slot name="content"></slot>
    <div class="forms-btns" slot="footer">
      <slot name="footer"></slot>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'mydialog',
  props: {
    width: {
      type: String,
      default: '900px'
    },
    title: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      closeFlag: false
    }
  },
  mounted() {
    
  },
  methods: {
    showModel(){
      this.closeFlag = true
    },
    closeModel() {
      this.closeFlag = false
      this.$emit("closeModel");
    },
  }
};
</script>
<style lang="less" scoped>
  .my-dialog ::v-deep .el-dialog {
    margin-top: 5vh !important;
  }
</style>

